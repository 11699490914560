import { configureStore } from "@reduxjs/toolkit";

import { combineReducers } from "redux";
import app from "store/app";

const rootReducers = combineReducers({
  app,
});

export type Store = ReturnType<typeof rootReducers>;

const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
